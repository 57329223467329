<template>
    <div class="center">
        <div v-if="isAdministrator()">
            <!-- Users -->
            <b-card class="cardHeader mb-2" header-bg-variant="dark" header-text-variant="white" header-class="customCardHeader">

                <template #header style="border-width:10px; ">
                    <span>User Administration</span>
                </template>

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Username
                                </th>
                                <th class="text-left">
                                    Email
                                </th>
                                <th class="text-left">
                                    Is Active
                                </th>
                                <th class="text-left">
                                    Is Locked
                                </th>
                                <th class="text-left">
                                    Is Deleted
                                </th>
                                <th class="text-left">
                                    Page Number
                                </th>
                                <th class="text-left">
                                 
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <v-text-field light
                                                  v-model="username"
                                                  placeholder="Username"
                                                  style="background-color:white; border-radius: 5px;"
                                                  dense>
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-text-field light
                                                  v-model="email"
                                                  placeholder="Email"
                                                  style="background-color:white; border-radius: 5px;"
                                                  dense>
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-checkbox v-model="isActive"></v-checkbox>
                                </td>
                                <td>
                                    <v-checkbox v-model="isLocked"></v-checkbox>
                                </td>
                                <td>
                                    <v-checkbox v-model="isDeleted"></v-checkbox>
                                </td>
                                <td>
                                    <v-text-field light
                                                  v-model="pageNumber"
                                                  placeholder="1"
                                                  style="background-color:white; border-radius: 5px;"
                                                  dense>
                                    </v-text-field>
                                </td>
                                <td>
                                    <b-button class="btn-primary ml-2" @click="alert('Toggle Active')" size="sm">
                                        <span style="font-family: Roboto, sans-serif; font-weight:bold;" class="text-start">Search</span>
                                        <font-awesome-icon :icon="['fas', 'search']" color="white" class="ml-2" />
                                    </b-button>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <b-card-body style="padding:0px; margin:0px; max-height:76vh !important; overflow-y: auto;">

                    <v-data-table :headers="userAdminFields"
                                  :items="searchedUsers"
                                  :hide-default-footer="true"
                                  :disable-pagination="true"
                                  :item-class="getDefaultRowStyle"
                                  class="elevation-5"
                                  dark>

                        <template v-slot:[`item.isActive`]="props">
                            <font-awesome-icon v-if="props.item.isActive" :icon="['fas', 'check']" color="green" class="ml-2" />
                            <font-awesome-icon v-if="!props.item.isActive" :icon="['fas', 'times']" color="red" class="ml-2" />
                        </template>

                        <template v-slot:[`item.isLocked`]="props">
                            <font-awesome-icon v-if="props.item.isLocked" :icon="['fas', 'lock']" color="red" class="ml-2" />
                            <font-awesome-icon v-if="!props.item.isLocked" :icon="['fas', 'lock-open']" color="green" class="ml-2" />
                        </template>

                        <template v-slot:[`item.isDeleted`]="props">
                            <font-awesome-icon v-if="props.item.isDeleted" :icon="['fas', 'check']" color="green" class="ml-2" />
                            <font-awesome-icon v-if="!props.item.isDeleted" :icon="['fas', 'times']" color="red" class="ml-2" />
                        </template>

                        <template v-slot:[`item.toggleActive`]="props">
                            <b-button class="btn-secondary ml-2" @click="toggleActive(props.item)" size="sm">
                                <font-awesome-icon v-if="props.item.isActive" :icon="['fas', 'user-times']" color="black" />
                                <font-awesome-icon v-if="!props.item.isActive" :icon="['fas', 'user-check']" color="#33FF3C" />
                            </b-button>
                        </template>

                        <template v-slot:[`item.toggleLock`]="props">
                            <b-button class="btn-secondary ml-2" @click="toggleLock(props.item)" size="sm">
                                <font-awesome-icon v-if="props.item.isLocked" :icon="['fas', 'lock-open']" color="#33FF3C" />
                                <font-awesome-icon v-if="!props.item.isLocked" :icon="['fas', 'lock']" color="black" />
                            </b-button>
                        </template>

                        <template v-slot:[`item.toggleDelete`]="props">
                            <b-button class="btn-secondary ml-2" @click="toggleDelete(props.item)" size="sm">
                                <font-awesome-icon v-if="props.item.isDeleted" :icon="['fas', 'user-plus']" color="#33FF3C" />
                                <font-awesome-icon v-if="!props.item.isDeleted" :icon="['fas', 'user-slash']" color="red" />
                            </b-button>
                        </template>


                    </v-data-table>
                    <span style="color:black;" class="float-right">Page {{currentPageNumber}} / {{totalPages}}</span>

                </b-card-body>
            </b-card>
        </div>
        <div v-if="!isAdministrator()">
            <h5 style="color:var(--var-theme-color)">Access Denied</h5>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { userService } from '../services/user-service';

    export default {
        name: 'UserManagement',

        /* COMPONENT DATA  */
        data() {
            return {
                username: null,
                email: null,
                isActive: true,
                isLocked: false,
                isDeleted: false,
                pageNumber: 1,
                currentPageNumber: 1,
                totalPages: 10,
                searchedUsers: [],
                userAdminFields: [
                    { text: 'Id', value: 'userID', sortable: false, class: 'userHeaderStyle' },
                    { text: 'User', value: 'username', sortable: false, class: 'userHeaderStyle' },
                    { text: 'Email', value: 'email', sortable: false, class: 'userHeaderStyle' },
                    { text: 'Active', value: 'isActive', sortable: false, class: 'userHeaderStyle' },
                    { text: 'Locked', value: 'isLocked', sortable: false, class: 'userHeaderStyle' },
                    { text: 'Deleted', value: 'isDeleted', sortable: false, class: 'userHeaderStyle' },
                    { text: 'Toggle Active', value: 'toggleActive', align: 'center', sortable: false, class: 'userHeaderStyle' },
                    { text: 'Toggle Lock', value: 'toggleLock', align: 'center', sortable: false, class: 'userHeaderStyle' },
                    { text: 'Toggle Delete', value: 'toggleDelete', align: 'center', sortable: false, class: 'userHeaderStyle' },
                ],
            };
        },

        /* LIFE CYCLE METHODS  */
        async created() {
            if (!this.isAdministrator()) //No Access to this page, redirect
                this.$router.push({ name: 'home' });

            var pageNumber = 1;
            var username = "";
            var email = "";
            var isLocked = null;
            var isActive = null;

            var users = await userService.searchForUsers(this, pageNumber, username, email, isLocked, isActive);
            if (users.success) {
                this.searchedUsers = users.value;;
            }
          
        },

        /* COMPONENT METHODS  */
        methods: {
            getDefaultRowStyle(record) {
                return "userRowStyle";
            },

            toggleActive(record) {
                alert("Active: " + record.isActive);
            },

            toggleLock(record) {
                alert("Locked: " + record.isLocked);
            },

            toggleDelete(record) {
                alert("Deleted: " + record.isDeleted);
            }

        },

        /* COMPUTED PROPERTIES */
        computed: {
            ...mapGetters(['isAdministrator']),
        },
    };
</script>

<style scoped>

    /deep/ .text-start {
        font-size: 12px !important;
    }

    /deep/ .userHeaderStyle {
        background-color: #76E23D !important;
        color: black !important;
        border-bottom: 1.1px solid gray !important;
    }

    /deep/ .userRowStyle {
        background-color: #BABFB8 !important;
        color: black !important;
        border-bottom: 1.1px solid gray !important;
    }

    /deep/ .card-body{
        padding: 0.5rem;
    }

    .cardHeader {
        border-color: #616161;
        padding: 0px;
        margin: 0px;
        border-width: 0px;
        border-radius: 20px;
        background-color: white;
    }

    .customCardHeader {
        height: 30px;
        padding-top: 3px;
        padding-left: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

</style>